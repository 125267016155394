// Labels in both languages
const titleContent = {
  es: '.: GUATAPAY :. | Somos el futuro de una economía sin fronteras.',
  en: '.: GUATAPAY :. | We are the future of an economy without borders.'
}

const footerContent = {
  services: {
    es: 'Servicios',
    en: 'Services'
  },
  resources: {
    es: 'Recursos',
    en: 'Resources'
  },
  about: {
    es: 'Sobre Nosotros',
    en: 'About us'
  },
  contact: {
    es: 'Contacto',
    en: 'Contact us'
  },
  nexsletter: {
    es: 'Recibe las últimas noticias de Guatapay en tu correo:',
    en: 'Receive the latest news from Guatapay in your email:'
  },
  button: {
    es: 'Suscribirse',
    en: 'Subscribe'
  },
  copyright: {
    es: 'Copyright © Guatapay 2023 - Todos los derechos reservados',
    en: 'Copyright © Guatapay 2023 - All rights reserved'
  },
  alert: {
    es: 'Suscripción realizada correctamente 👍',
    en: 'Subscription sent successfully 👍'
  }
}

const linksContent = {
  solutions: {
    label: {
      es: 'Soluciones',
      en: 'Solutions'
    },
    one: {
      es: 'Comercio electrónico',
      en: 'E-commerce'
    },
    two: {
      es: 'Servicios de pago',
      en: 'Paid services'
    },
    three: {
      es: 'Link de pagos',
      en: 'Payment link'
    }
  },
  about: {
    es: 'Sobre Nosotros',
    en: 'About us'
  },
  contact: {
    es: 'Contacto',
    en: 'Contact'
  },
  language: {
    spanish: {
      es: 'Español',
      en: 'Spanish'
    },
    english: {
      es: 'Inglés',
      en: 'English'
    }
  }
}

const homeContent = {
  hero: {
    easy: {
      es: 'Fácil, rápido y seguro',
      en: 'Easy, fast and safe'
    },
    send: {
      es: 'Envía y recibe dinero sin fronteras',
      en: 'Send and receive money without borders'
    },
    never: {
      es: 'Con Guatapay nunca ha sido tan fácil, rápido y seguro el envío de remesas a bajos costos de comisión.',
      en: 'With Guatapay it has never been so easy, fast and secure to send remittances at low commission costs.'
    },
    more: {
      es: 'Más información',
      en: 'More information'
    }
  },
  everyCorner: {
    title: {
      es: 'Llegamos a cada rincón',
      en: 'We reach everywhere'
    },
    coming: {
      es: 'Próximamente',
      en: 'Coming soon'
    },
    paragraph: {
      es: 'Tenemos presencia en los principales países donde los latinoamericanos necesitan nuestra solución. Nuestra misión es llevar nuestro servicio a cada rincón del mundo.',
      en: 'We have presence in the main countries where Latin Americans need our solution. Our mission is to bring our service to every corner of the world.'
    },
    eeuu: {
      es: 'Estados Unidos',
      en: 'United States'
    },
    pe: {
      es: 'Perú',
      en: 'Peru'
    },
    br: {
      es: 'Brasil',
      en: 'Brazil'
    },
    es: {
      es: 'España',
      en: 'Spain'
    }
  },
  complexities: {
    easy: {
      es: 'Fácil, rápido y seguro',
      en: 'Easy, fast and safe'
    },
    complexities: {
      es: 'Sin complejidades',
      en: 'Without complexities'
    },
    move: {
      es: 'Mueve tu dinero libremente, fácil y al instante. Tenemos las comisiones mas bajas del mercado.*',
      en: 'Move your money freely, easily and instantly. We have the lowest commissions in the market.*'
    },
    register: {
      es: 'Registrarse Ahora',
      en: 'Register Now'
    },
    cost: {
      es: '*1.2% costo promedio por transacción transfronteriza.',
      en: '*1.2% average cost per cross-border transaction.'
    },
    available: {
      es: 'Disponible: 10,652.31',
      en: 'Available: 10,652.31'
    }
  },
  testimony: {
    paragraph: {
      es: '"Guatapay me da la flexibilidad que necesito, permitiéndome enviar dinero a mi familia en Colombia a costos realmente bajos"',
      en: '"Guatapay gives me the flexibility I need, allowing me to send money to my family in Colombia at really low costs."'
    },
    context: {
      es: 'Colombiana viviendo en Estados Unidos',
      en: 'Colombian living in the United States'
    }
  },
  request: {
    paragraph: {
      es: 'Olvídate de las complejidades y comienza a mover tu dinero libremente de forma rápida y a bajos costos de comisión hoy mismo.',
      en: 'Forget the complexities and start moving your money freely, quickly and at low commission costs today.'
    },
    register: {
      es: 'Registrarse Ahora',
      en: 'Register Now'
    }
  }
}

const aboutContent = {
  about: {
    title: {
      es: 'Nosotros',
      en: 'Who we are'
    },
    paragraph: {
      es: 'Somos un grupo de amantes de Bitcoin y su tecnología. Estamos convencidos que Bitcoin hará de la industria financiera un espacio justo, incluyente y de acceso abierto.',
      en: 'We are a group of Bitcoin lovers and its technology. We are convinced that Bitcoin will make the financial industry a fair, inclusive and open access space.'
    }
  },
  guatapay: {
    span: {
      es: 'QUEREMOS SER PARTE DE LA CONSTRUCCIÓN DE UN',
      en: 'WE WANT TO BE PART OF THE CONSTRUCTION OF A'
    },
    long: {
      es: 'Nuevo sistema financiero, en el que las criptomonedas sean la infraestructura en la que cualquier usuario se transforme en un microcanal, democratizando el poder del dinero.',
      en: 'New financial system, in which cryptocurrencies are the infrastructure in which any user becomes a micro-channel, democratizing the power of money.'
    },
    mobile: {
      es: 'Queremos ser parte de la construcción de un nuevo sistema financiero en el que las criptomonedas sean la infraestructura en la que cualquier usuario se transforme en un microcanal, democratizando el poder del dinero.',
      en: 'We want to be part of the construction of a new financial system in which cryptocurrencies are the infrastructure in which any user becomes a micro-channel, democratizing the power of money.'
    }
  },
  weBelieve: {
    es: 'Por eso, creamos la infraestructura de pagos en criptomonedas que permitirá a las personas, comercios y empresas transformarse en microentidades que puedan proveer servicios financieros alternativos y abrir fácilmente canales transaccionales digitales alrededor del mundo.',
    en: "That's why we created the cryptocurrency payment infrastructure that will enable individuals, merchants and businesses to transform themselves into micro-entities that can provide alternative financial services and easily open digital transactional channels around the world."
  },
  currencies: {
    es: 'Guatapay es una infraestructura de aceptación de pagos con Bitcoin, Lightning Network y las criptomonedas de mayor impacto en el entorno económico global.',
    en: 'Guatapay is a payment acceptance infrastructure for Bitcoin, Lightning Network and the cryptocurrencies with the greatest impact on the global economic environment.'
  },
  soon: {
    es: 'Proximamente mas',
    en: 'More coming soon'
  },
  ourService: {
    es: 'Nuestro servicio de gateway de pagos permite a las personas, comercios y compañías realizar y recibir pagos instantáneamente, 24/7, desde cualquier lugar conectado a internet en el mundo, convirtiéndolos en tu moneda local y, lo mejor de todo, al costo más competitivo del mercado de pagos digitales global.',
    en: 'Our payment gateway service allows individuals, merchants and companies to make and receive payments instantly, 24/7, from any internet-connected location in the world, converting them into your local currency and, best of all, at the most competitive cost in the global digital payments market.'
  }
}

const solutionsContent = {
  global: {
    a: {
      es: 'Saber más',
      en: 'Learn more'
    }
  },
  solutions: {
    title: {
      es: 'Soluciones Guatapay',
      en: 'Guatapay solutions'
    },
    paragraph: {
      es: 'Somos la primera solución integral de pagos y transferencias borderless que permite simplificar la manera como los comercios y personas envían reciben y almacenan su dinero a nivel global.',
      en: 'We are the first comprehensive borderless payment and transfer solution that simplifies the way merchants and individuals send, receive and store their money globally.'
    }
  },
  container: {
    title: {
      es: 'Comercio electrónico',
      en: 'E-commerce'
    },
    paragraph: {
      es: 'Integra la pasarela de pagos de Guatapay con tu tienda online en unos pocos clics y atrae a nuevos clientes en todo el mundo.',
      en: "Integrate Guatapay's payment gateway with your online store in a few clicks and attract new customers worldwide."
    }
  },
  container_one: {
    title: {
      es: 'Proveedor de servicios de pago',
      en: 'Payment service provider'
    },
    paragraph: {
      es: 'Añade criptomonedas como nuevo método de pago a tu pasarela de pago y captura las transacciones de más clientes.',
      en: 'Add cryptocurrencies as a new payment method to your payment gateway and capture more customers transactions.'
    }
  },
  container_two: {
    title: {
      es: 'Solicitud de pagos con link',
      en: 'Payment request with link'
    },
    paragraph: {
      es: 'Acepta pagos con criptomonedas con un solo enlace y sin codificación. Crea el enlace de pago y envíalo por correo electrónico.',
      en: 'Accept cryptocurrency payments with a single link and no encryption. Create the payment link and send it by email.'
    }
  },
  registrate: {
    paragraph: {
      es: 'Olvídate de las complejidades y comienza a mover tu dinero libremente de forma rápida y a bajos costos de comisión hoy mismo.',
      en: 'Forget the complexities and start moving your money freely, quickly and at low commission costs today.'
    },
    a: {
      es: 'Registrarse ahora',
      en: 'Register now'
    }
  }
}

const contactContent = {
  header: {
    title: {
      es: 'Contactar',
      en: 'Contact us'
    },
    paragraph: {
      es: 'Si deseas reservar una demostración o solicitar más información sobre nuestros servicios, por favor completa el siguiente formulario y nos pondremos en contacto contigo en la brevedad.',
      en: 'If you wish to book a demonstration or request more information about our services, please complete the following form and we will contact you as soon as possible.'
    }
  },
  form: {
    name: {
      placeholder: {
        es: 'Ingresa tu nombre',
        en: 'Enter your name'
      },
      es: 'Nombre',
      en: 'Name'
    },
    email: {
      es: 'Ingresa tu correo',
      en: 'Enter your email'
    },
    company: {
      placeholder: {
        es: 'Ingresa tu compañía',
        en: 'Enter your company'
      },
      es: 'Compañía (opcional)',
      en: 'Company (optional)'
    },
    phone: {
      es: 'Télefono',
      en: 'Phone'
    },
    message: {
      placeholder: {
        es: 'Escribe tu mensaje...',
        en: 'Write your message...'
      },
      es: 'Mensaje',
      en: 'Message'
    },
    submit: {
      es: 'Enviar mensaje',
      en: 'Send message'
    },
    alert: {
      es: 'Mensaje enviado con exito 👍',
      en: 'Message sent successfully 👍'
    }
  },
  errors: {
    name: {
      es: 'El campo nombre no puede estar vacio',
      en: 'The name field cannot be empty'
    },
    email: {
      es: 'El campo email no puede estar vacio',
      en: 'The email field cannot be empty'
    },
    phone: {
      es: 'El campo telefono no puede estar vacio',
      en: 'The phone field cannot be empty'
    },
    message: {
      es: 'El campo mensaje no puede estar vacio',
      en: 'The message field cannot be empty'
    },
    emailFormat: {
      es: 'Este campo debe tener un formato de correo valido',
      en: 'This field must have a valid email format'
    },
    phoneFormat: {
      es: 'Este campo debe tener un numero telefónico valido',
      en: 'This field must have a valid phone number'
    },
    messageLength: {
      es: 'El mensaje no puede contener mas de 200 caracteres',
      en: 'The message cannot contain more than 200 characters'
    }
  },
  policy: {
    es: 'Al pulsar el botón “Enviar mensaje”, acepto que Guatapay se ponga en contacto conmigo por correo electrónico y/o teléfono. También entiendo que cualquier información que he compartido en este formulario está sujeta a la Política de Privacidad de Guatapay.',
    en: "By clicking the “Send message” button, I agree to be contacted by Guatapay by email and/or phone. I also understand that any information I have shared on this form is subject to Guatapay's Privacy Policy."
  }
}
